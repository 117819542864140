import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataChatService } from '../services/data-chat.service';

@Injectable({
  providedIn: 'root'
})
export class DataChatResolverService {

  constructor(private dataChatService: DataChatService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let id = route.paramMap.get('chatname');
    return this.dataChatService.getData(id);
  }
}
