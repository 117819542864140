// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.api.mimedico.startapps.com.pe/api/',
  //apiUrl: 'http://192.168.1.3/api/',
  // apiUrl: 'http://localhost/api/',
  twilioConfiguration: {
    "accountSid": "AC935883bacf8b3498119f1762b96174f8",
    "signingKeySid": "SK9ec5f39b2539aed87c29c49400612118",
    "signingKeySecret": "LYMjM0OE8Vtns1v3jbdO10136Tf4hLUv",
    "serviceSid": "ISa71c103f17ef42d7bbeb4093fdaa9b94",
    "pushCredentialSid": "CRf2ba65274ed66ff9a118889d4dc7213e"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
