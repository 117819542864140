import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataChatResolverService } from './resolver/data-chat-resolver.service';
import { DataConsultationResolverService } from './resolver/data-consultation-resolver.service';
import { DataHistoryResolverService } from './resolver/data-history-resolver.service';
import { DataMedicineResolverService } from './resolver/data-medicine-resolver.service';
import { DataRecetaResolverService } from './resolver/data-receta-resolver.service';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'send-code',
    loadChildren: () => import('./send-code/send-code.module').then( m => m.SendCodePageModule)
  },
  {
    path: 'chatconsultant/:chatname',
    resolve: {
      special: DataChatResolverService
    },
    loadChildren: () => import('./components/chatconsultant/chatconsultant.module').then( m => m.ChatconsultantPageModule)
  },
  {
    path: 'videoconsultant/:id',
    resolve: {
      special: DataChatResolverService
    },
    loadChildren: () => import('./components/videoconsultant/videoconsultant.module').then( m => m.VideoconsultantPageModule)
  },
  {
    path: 'send-code2/:phone',
    loadChildren: () => import('./send-code2/send-code2.module').then( m => m.SendCode2PageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./account/share/share.module').then( m => m.SharePageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./account/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'receta/:chatname/:consultation_id',
    resolve: {
      special: DataRecetaResolverService
    },
    loadChildren: () => import('./request/receta/receta.module').then( m => m.RecetaPageModule)
  },
  {
    path: 'receta-detail/:chatname/:consultation_id/:medicine_id',
    resolve: {
      special: DataMedicineResolverService
    },
    loadChildren: () => import('./request/receta-detail/receta-detail.module').then( m => m.RecetaDetailPageModule)
  },
  {
    path: 'order/:chatname/:consultation_id',
    loadChildren: () => import('./request/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'order-detail/:chatname/:consultation_id/:medicine_id',
    loadChildren: () => import('./request/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'history/:chatname/:user_id',
    resolve: {
      special: DataHistoryResolverService
    },
    loadChildren: () => import('./request/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'history-detail',
    loadChildren: () => import('./request/history-detail/history-detail.module').then( m => m.HistoryDetailPageModule)
  },
  {
    path: 'consulta-preview/:chatname/:consultation_id',
    resolve: {
      special: DataConsultationResolverService
    },
    loadChildren: () => import('./request/consulta-preview/consulta-preview.module').then( m => m.ConsultaPreviewPageModule)
  },
  {
    path: 'broadcast-load',
    loadChildren: () => import('./request/broadcast-load/broadcast-load.module').then( m => m.BroadcastLoadPageModule)
  },
  {
    path: 'broadcast-motivo',
    loadChildren: () => import('./request/broadcast-motivo/broadcast-motivo.module').then( m => m.BroadcastMotivoPageModule)
  },
  {
    path: 'diagnostic/:chatname/:consultation_id',
    //resolve: {
    //  special: DataConsultationResolverService
    //},
    loadChildren: () => import('./request/diagnostic/diagnostic.module').then( m => m.DiagnosticPageModule)
  },
  {
    path: 'viewimage',
    loadChildren: () => import('./components/viewimage/viewimage.module').then( m => m.ViewimagePageModule)
  },
  {
    path: 'ingresos',
    loadChildren: () => import('./report/ingresos/ingresos.module').then( m => m.IngresosPageModule)
  },
  {
    path: 'history-consults',
    loadChildren: () => import('./request/history-consults/history-consults.module').then( m => m.HistoryConsultsPageModule)
  },
  {
    path: 'paciente-detail',
    loadChildren: () => import('./components/paciente-detail/paciente-detail.module').then( m => m.PacienteDetailPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
