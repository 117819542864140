import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataHistoryService } from '../services/data-history.service';

@Injectable({
  providedIn: 'root'
})
export class DataHistoryResolverService {

  constructor(private dataHistoryService: DataHistoryService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let id = route.paramMap.get('user_id');
    return this.dataHistoryService.getData(id);
  }
}
