import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataConsultationService {

  private data = [];
 
  constructor(
    public storageService: StorageService
  ) { }
 
  setData(id, data) {
    this.data[id] = data;
    this.storageService.store(`consultation_${id}`, data);
  }
 
  getData(id) {
    return this.storageService.get(`consultation_${id}`).then(result => {
      if(result){
        return result;    
      }
      return this.data[id];
    });
  }
}
