import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataMedicineService } from '../services/data-medicine.service';

@Injectable({
  providedIn: 'root'
})
export class DataMedicineResolverService {

  constructor(private dataMedicineService: DataMedicineService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let id = route.paramMap.get('medicine_id');
    return this.dataMedicineService.getData(id);
  }
}
