import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DataConsultationService } from '../services/data-consultation.service';

@Injectable({
  providedIn: 'root'
})
export class DataConsultationResolverService implements Resolve<any> {
 
  constructor(private dataConsultationService: DataConsultationService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let id = route.paramMap.get('consultation_id');
    return this.dataConsultationService.getData(id);
  }
}
